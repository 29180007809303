<template>
    <div :style="{ height: screeHeight + 'px' }" style="background-color: #F7F7F7;">
        <div v-if="imgs.length < 1" style="height: 20px;"></div>
        <van-swipe :autoplay="2000" @change="onChange">
            <van-swipe-item v-for="(image, index) in imgs" :key="index" >
                <img :src="image.imageUrl" style="width:100%;height: 180px;"/>
            </van-swipe-item>
            <template #indicator>
                <div class="custom-indicator">{{ imgindex + 1 }}/{{imgs.length}}</div>
            </template>
        </van-swipe>
        <div class="infoCenter" style="">
            <div style="margin: 5px;">
                <div @click="$router.push('/xuzhi')" style="font-size: 23px;font-weight: bold;padding: 3px 3px;">{{info.name}} 
                <span class="xuzhi" >游客须知&nbsp;<img src="../../assets/right.png" style="width: 12px;"/></span></div>
                <van-rate
                :value="info.score || 5"
                allow-half
                readonly
                :size="15"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                style="margin-left: 2px;"
                />
                <span v-if="info.score" style="font-size: 20px;color: #ffd21e;margin-left: 10px;">{{info.score.toFixed(1)}}</span>
                <span v-else style="font-size: 20px;color: #ffd21e;margin-left: 10px;">5.0</span>
                <div style="padding: 4px 0;display: flex;align-items: center;">
                    <!-- <span class="jianjie">景点简介</span>  -->
                    <img src="../../assets/jingdianjianjie.png" style="width: 85px;height: 27px;">
                    <div class="jianjieInfo" style="">{{info.organizationType }} {{info.organizationGrade}}</div>
                </div>
                <van-row>
                    <van-col span="18" style="padding: 10px 5px;font-size: 15px;color: #333333;"><div>{{info.provinceName}}{{info.cityName}}{{info.districtText}}</div></van-col>
                    <!-- <van-col span="6">
                        <img src="../../assets/map.png" style="width: 55px;position: relative;top: -10px;" alt="" >
                        <div>地图/周边</div>
                    </van-col> -->
                </van-row>
                <!-- <div style="font-size: 18px;font-weight: bold;margin-top: -18px;position: absolute;">门票</div> -->
                
                <!-- <van-row style="margin: 15px 0;">
                    <van-col :span="24/(lists.length+2)" @click="active = 'all'"><div :class="active === 'all' ? 'active' : 'nactive'">全部</div></van-col>
                    <van-col v-for="(item,index) in lists" :key="index" :span="24/(lists.length+1)" @click="active = index"><div :class="active === index ? 'active' : 'nactive'">
                        {{item.typeName}}</div></van-col>
                </van-row> -->
            </div>
            
        </div>
        <div class="nav">
            <a @click="active = 'all'" :class="active === 'all' ? 'active' : ''">全部</a>
            <a v-for="(item,index) in lists" :key="index" @click="active = index" :class="active === index ? 'active' : ''">
                {{item.typeName}}
            </a>
        </div>
        <myList 
            :list="nowList" 
            :isAdd="true" 
            :height="-120" 
            :nonav="true" 
            :toProduct="true" 
            @getList="getList" 
            style="padding-bottom: 46px;margin-top: -10px;"/>
        <van-row class="sum">
            <div style="width: 95%;margin: 0 auto;color: rgb(0 0 0 / 70%);">
                小结：<span style="color: #ff5345;font-weight: bold;">{{'￥ '+sum.toFixed(2) +'元'}}</span> 
                <span style="float: right;" class="sub" @click="toAddOrder">立即预定</span> 
            </div>
        </van-row>
    </div>
</template>
<script>
import myList from '../../components/list';
import { Toast } from 'vant';
export default {
  name: 'info',
  components:{
      myList
  },
  data(){
        return {
            screenWidth: document.documentElement.clientWidth,     // 屏幕宽
            screeHeight: document.documentElement.clientHeight,    // 屏幕高
            groupOptId:'',
            info:'',
            lists:[],
            active:'all',
            imgindex:0,
            imgs:[],//[require('../../assets/img1.png'),require('../../assets/img2.png')],
            allProductList:[],
            nowList:[],
            sum:0,
            orderArr:[]
        }
    },
    // computed: {
    //   listenList() {
    //     return this.$store.state.products;
    //   }
    // },
    watch: {
        $route(){
            this.getInfo()
        },
        active(){
            this.getProductList()
        },
    },
    mounted(){
        this.getInfo()
        //清空购物车
        this.$http({
            method: "post",
            url: `${this.apis.cleanCart}`,
        }).then((res) => {
            if (res.code && res.code == 200) {
                this.$store.commit('saveOrders',[]) 
                localStorage.removeItem('orders')
            }
        })
    },
    methods:{
        getList(list){
            //数量增加减少动作回调
            if(this.active==='all'){ //所有
                this.allProductList = list;
            }else{
                this.lists[this.active].productList = list
                this.nowList=this.lists[this.active] ? this.lists[this.active].productList : []
                this.allProductList = []
                this.getAllProductList()
            }
            this.toSum() //计算总数
        },
        /**
         * toAddOrder
         * 加入购物车
         */
        toAddOrder(){
            if(this.orderArr.length < 1){
                Toast({
                    message: '未选择门票',
                    icon: 'circle',
                });
                return
            }
            this.$loading.show()
            let op = 0;
            this.orderArr.forEach(ite => {
                //1校验
                this.$http({
                    method: "post",
                    url: `${this.apis.addValid}`,
                    params: {
                        productId: ite.productId,
                        count:ite.count,
                        isSales:0
                    }
                }).then((res) => {
                    if (res.code && res.code == 200) {
                        //2加入购物车
                        return this.$http({
                            method: "post",
                            url: `${this.apis.add}`,
                            params: {
                                productId: ite.productId,
                                count:ite.count,
                                isSales:0
                            }
                        })
                    }else{
                        //不允许购买
                        Toast({
                            message: res.message,
                            icon: 'fail',
                        });
                        this.clearCart();
                        return
                    }
                }).then((res) => {
                    console.log(res)
                    this.$loading.hide()
                    if(res.code == 200){
                        op++
                        ite.cartId = res.cartId
                        if(op === this.orderArr.length){
                            //3 确认购物车 confirm
                            this.toConfirm(this.orderArr,this.orderArr[0].isAppointment)
                        }
                    }
                })
            })
        },
        /**
         * 检查是否可购买
         */
        toConfirm(arr,isAppointment) {
            let cartIds = arr.map((ite) => {
                return ite.cartId;
            });
            this.$loading.hide()
            if (cartIds.length > 0) {
                this.$http({
                    method: "post",
                    url: `${this.apis.confirm}`,
                    params: { cartIds: cartIds.toString() },
                }).then((res) => {
                    console.log(res);
                    if(res.code && res.code == 200){
                        // this.carts = res.data.productList;
                        this.$store.commit('saveOrders',res.data) //存给下单页面
                        localStorage.setItem('orders',JSON.stringify(res.data))
                        this.$router.push({path: '/addOrder',query:{isAppointment}});//, query: this.info
                    }else{
                        //不允许购买
                        Toast({
                            message: res.message,
                            icon: 'fail',
                        });
                        this.clearCart();
                        return
                    }
                    
                });
            }
        },
        clearCart(){
            this.$http({
                method: "post",
                url: `${this.apis.cleanCart}`,
            }).then((res) => {
                localStorage.removeItem("orders")
                console.log(res)
            })
        },
        onChange(index) {
            this.imgindex = index;
        },
        toSum(){
            //计算小结
            let arr = []
            let sum = 0;
            this.allProductList.forEach(ite => {
                if(ite.num > 0){
                    sum = sum + ite.num * ite.salePrice;
                    arr.push({productId:ite.id,count:ite.num,...ite}); //ite.num > 0 写入购物列表
                }
            })
            this.orderArr = arr
            this.sum = sum
        },
        getInfo(){
            this.groupOptId = this.$route.query.id
            this.queryInfo()
            // this.info.rate = +this.info.rate
        },
        queryInfo(){
            this.$loading.show()
            //获取景区详情
            this.$http({
                method: "post",
                url: `${this.apis.getOrgInfo}?groupOptId=${this.groupOptId}`,
                // data:{groupOptId:this.groupOptId}
            }).then((res) => {
                this.$loading.hide()
                if (res.code == 200) {
                    this.info = res.orgInfo
                    this.imgs = res.carouselList
                    this.$store.commit('saveOrgInfo',this.info) //存给游客须知使用
                    
                }
            })
            //获取景区产品列表
            this.$http({
                method: "post",
                url: `${this.apis.groupSubOrgProductList}?groupOptId=${this.groupOptId}`,
            }).then((res) => {
                if (res.code == 200) {
                    this.lists = res.data
                    this.getAllProductList()
                    this.allProductList.map(ite => {
                        ite.num = ite.num==undefined ? '0' : ite.num
                    })
                    this.getProductList()
                }
            });
        },
        /**
         * getAllProductList
         * 把所有产品全部放allProductList
         */
        getAllProductList(){
            this.lists.map(ite => {
                this.allProductList = this.allProductList.concat(ite.productList)
            })
        },
        /**
         * getProductList
         * 获取当前active 页产品nowList
         */
        getProductList(){
            if(this.active==='all'){ //所有
                this.nowList=this.allProductList;
            }else{
                this.nowList=this.lists[this.active] ? this.lists[this.active].productList : []
            }
            this.nowList.map(ite => {
                ite.minPrice = ite.originalPrice
            })
            
        },
        toSearch(){
            this.onSearch()
            this.isSearch = true
        },
        onSearch(){
            this.isSearch = false
            if(this.searchValue == ''){
                this.seaList = []
                return
            }
            this.seaList = this.list.filter(ite => ite.name.includes(this.searchValue))
        },
        keyRegExp(val, keyword) {
            val = val + '';
            if (val.indexOf(keyword) !== -1 && keyword !== '') {
                return val.replace(keyword, '<font color="#f00">' + keyword + '</font>')
            } else {
                return val
            }
        },
    },
}
</script>
<style lang="less" scoped>
.infoCenter{
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 1px #dcdee0;
    top: -15px;
    background: white;
    .xuzhi{
        font-size: 15px;
        float: right;
        font-weight: 400;
        line-height: 25px;
        font-family: ui-rounded;
        color: #333333;
        margin-top: 5px;
    }
    .jianjie{
        // background-color: #ff8917;
        background-image: linear-gradient(to top, #ff830e 0%, #ff683f 100%);
        color: white;
        font-size: 14px;
        border-radius: 5px;
        padding: 3px;
        display: inline-block;
        margin-left: 5px;
        
    }
    .jianjieInfo{
        color: #ff6c6c;
        background-color: #ffedd9;
        font-size: 14px;
        padding: 3px;
        padding-right: 8px;
        border-radius: 0 3px 3px 0;
        margin-left: -1px;
    }
}
.active{
    width: 70px;
    background-color: #FF5008;
    color: white !important;
    text-align: center;
    border-radius: 15px;
    padding: 6px 11px;
}
.nactive{
    width: 70px;
    padding: 6px 11px;
    text-align: center;
    border-radius: 15px;
}
.sum{
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100%;
    background-color: #f2f3f5;
    height: 55px;
    line-height: 55px;
}
.sub{
    width: 85px;
    background-color: #ff5345;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 5px 10px;
    line-height: 25px;
    margin-top: 10px;
}
.custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 15px;
    padding: 2px 5px;
    font-size: 12px;
    color: white;
    background: rgba(0, 0, 0, 0.1);
  }
  .nav{
    width: 100%;
    height: 50px;
    line-height: 45px;
    /*段落中文本不换行*/
    white-space: nowrap;
    box-shadow: 0 0 1px #dcdee0;
    /*阴影*/
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, .2); */
    /*设置横向滚动*/
    overflow-x: scroll;
    /*禁止纵向滚动*/
    overflow-y: hidden;
    /*文本平铺*/
    text-align: justify;
    /*背景颜色*/
    background: white;
    /* padding: 0px 5px; */
    /* margin-bottom: 10px; */
    /*设置边距改变效果为内缩*/
    box-sizing: border-box;
    border-radius: 12px 12px 0 0;

  }
  .nav a {
	color: #333333;
    text-decoration: none;
    margin: auto 8px;
    font-family: 'SourceHanSansCN-Regular';
}
.nav::-webkit-scrollbar {
	/*隐藏滚动条*/
	/* display: none; */
}
</style>